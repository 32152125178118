.footer {
  background-color: #222;
  color: #fff;
  padding: 2rem 0;
}

.footerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.footerContent h3 {
  margin-bottom: 1rem;
}

.footerContent ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footerContent ul li {
  margin: 0 1rem;
}

.footerContent ul li a {
  color: #fff;
  text-decoration: none;
}

.footerContent ul li a:hover {
  text-decoration: underline;
}

.footerCopy {
  text-align: center;
  font-size: 0.9rem;
}