/* src/styles/hero.module.css */

.hero {
  background-image: url('/src/images/hero2.png');
  background-size: cover;
  background-position: center;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero h1 {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 1rem;
  max-width: 90%;
}

.hero p {
  font-size: 1.2rem;
  color: #fff;
  max-width: 90%;
}

.hero button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 2rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.hero button:hover {
  background-color: #555;
}
/* src/styles/featuredCities.module.css */

.featuredCities {
  padding: 2rem 0;
  background-color: #fff;
}

.featuredCities h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.citiesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.city {
  flex: 0 0 calc(33.333% - 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f4f4f4;
  margin-bottom: 1rem;
  min-width: 320px;
}

.city img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.city h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.city p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.city button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
font-size: 1rem;
cursor: pointer;
transition: background-color 0.3s ease-in-out;
}

.city button:hover {
background-color: #555;
}

.city button:disabled {
background-color: #999;
cursor: not-allowed;
}