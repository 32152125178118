/* src/components/Header.module.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #222;
}

.header h1 {
  color: #fff;
  margin: 0;
}

.header h1 a {
  color: inherit;
  text-decoration: none;
}

.navbar {
  display: flex;
  gap: 1rem;
}

.navbar a {
  color: #fff;
  text-decoration: none;
}

.navbar a:hover {
  text-decoration: underline;
}

.menuIcon {
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  .menuIcon {
    display: block;
    color: #fff;
  }

  .navbar.show {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .logoicon {
    display: none;
  }
}