/* src/pages/ComingSoonPage.module.css */

.comingSoonPage {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.comingSoonPage a {
  color: #f07c42;
  text-decoration: none;
}

.comingSoonPage a:hover {
  text-decoration: underline;
}