/* src/styles/cityPage.module.css */

.cityPage {
  padding: 2rem;
  background-color: #fff;
}

.cityPage h1,
.cityPage h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.cityPage img {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.cityPage p {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: justify;
}

.cityPage ul {
  list-style-type: disc;
  margin-left: 2rem;
}

.cityPage .map {
  /* Add your custom map styling here */
  
  display: flex;
  align-items: center;
  margin:auto;
  justify-content: center;
}

.cityPage .packages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cityPage .package {
  flex: 0 0 calc(33.333% - 2rem);
  margin-bottom: 2rem;
}

.cityPage .stepByStep {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cityPage .step {
  flex: 0 0 calc(33.333% - 2rem);
  margin-bottom: 2rem;
  min-width: 320px;
}