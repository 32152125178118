/* src/styles/global.css */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
  min-width: 320px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}