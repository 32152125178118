/* src/styles/testimonials.module.css */

.testimonials {
  padding: 2rem 0;
  background-color: #fff;
}

.testimonials h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.testimonialContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.testimonial {
  flex: 0 0 calc(33.333% - 1rem);
  background-color: #f4f4f4;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
  min-width: 320px;
}

.testimonial h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.testimonial p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.testimonial small {
  display: block;
  font-size: 0.8rem;
}