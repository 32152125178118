/* src/styles/aboutUsPage.module.css */

.aboutUsPage {
  padding: 2rem;
  background-color: #fff;
}

.aboutUsPage h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.aboutUsPage img {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.aboutUsPage p {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: justify;
}